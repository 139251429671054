var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", [_vm._v(_vm._s(_vm.$t("admin.pharmacy.create")))]),
      _c(
        "b-form",
        { staticClass: "mt-4", on: { submit: _vm.onSubmit } },
        [
          _c(
            "b-row",
            { staticClass: "mb-5" },
            [
              _c(
                "b-col",
                { staticClass: "inputGroup" },
                [
                  _c("label", { attrs: { for: "input-1" } }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("admin.pharmacy.name")))])
                  ]),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-1",
                      placeholder: this.$i18n.t("admin.pharmacy.nameInput"),
                      required: ""
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "inputGroup" },
                [
                  _c("label", { attrs: { for: "input-2" } }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("admin.pharmacy.code")))])
                  ]),
                  _c("b-form-input", {
                    attrs: { id: "input-2", placeholder: "IMC", required: "" },
                    model: {
                      value: _vm.form.imc,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "imc", $$v)
                      },
                      expression: "form.imc"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-5" },
            [
              _c(
                "b-col",
                { staticClass: "inputGroup" },
                [
                  _c("label", { attrs: { for: "input-3" } }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("admin.pharmacy.locality")))])
                  ]),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-3",
                      placeholder: this.$i18n.t("admin.pharmacy.locality"),
                      required: "",
                      type: "number"
                    },
                    model: {
                      value: _vm.form.localidad,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "localidad", $$v)
                      },
                      expression: "form.localidad"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "inputGroup" },
                [
                  _c("label", { attrs: { for: "input-4" } }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.$t("admin.pharmacy.branchInput")))
                    ])
                  ]),
                  _c("b-form-select", {
                    attrs: {
                      id: "input-4",
                      options: _vm.sedeOrigen,
                      required: ""
                    },
                    model: {
                      value: _vm.form.sede,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sede", $$v)
                      },
                      expression: "form.sede"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "justify-content-end" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn btn-blue-dark-outline ml-0",
                  on: {
                    click: function($event) {
                      return _vm.$emit("handleCancel")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("admin.pharmacy.cancel")) + " ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "btn btn-blue-dark ml-0",
                  attrs: { type: "submit", disabled: _vm.isLoading }
                },
                [_vm._v(" " + _vm._s(_vm.$t("admin.pharmacy.save")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }