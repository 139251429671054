<template>
  <section>
    <b-row class="mb-3 justify-content-between">
      <b-col class="select-sede">
        <span><b>{{ $t('admin.pharmacy.branch')}}:</b></span>
        <div>
          <b-dropdown size="md"  :text="sedeInput" class="m-md-2" variant="outline-white">
            <div  class="groupSearch my-3"> 
              <span></span>
              <b-input-group size="sm" class="inputSearch">
                <b-form-input
                    id="filter-input"
                    v-model="filterSede"
                    type="search"
                    :placeholder="this.$i18n.t('admin.pharmacy.search')"
                    class="custom-input"
                > </b-form-input>
                </b-input-group>
              </div>
            <b-dropdown-divider />
            <b-dropdown-text>{{ $t('admin.pharmacy.branches')}}</b-dropdown-text>
            <div v-for="(item, index) in sucursalesFilter" :key="index" class="item__dropdown">
              <b-dropdown-item :data-name="item" @click="handleSede(item)">{{item.name}}</b-dropdown-item>
            </div>
          </b-dropdown>
        </div>

    </b-col>
    <b-col class="text-right" v-if="configRol.administrador.pestana.pharmacy.acciones.crear">
      <b-button
         class="btn btn-blue-dark ml-0"
         @click="$emit('handleCreate')"
        >
            <span>{{ $t('admin.pharmacy.create')}} +</span>
        </b-button>
    </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <small class="text-secondary">{{ $t('admin.pharmacy.Associated')}}</small>
        <div class="mt-3" v-if="configRol.administrador.pestana.pharmacy.acciones.listar">
        
        <b>{{messageError}}</b>
        <div class="tablePharmacy" v-for="(item, index) in farmaciasList" :key="index">
          <b>{{item.nombre}}</b>
          <span v-if="configRol.administrador.pestana.pharmacy.acciones.eliminar" class="disassociatePharmacy" @click="handleDeletePharmacy(item)">x {{ $t('admin.pharmacy.Disassociate')}}</span>
          <b-dropdown  id="dropdown-sede"  size="md" dropleft menu-class="form-sede" variant="link" :ref="`dropdownSede${index}`" no-caret toggle-class="text-decoration-none ml-3 p-0"
            v-if="configRol.administrador.pestana.pharmacy.acciones.editar"
          >
            <template #button-content>
              <span @click="editPharmacy(item)"><b-icon class="p-0" icon="three-dots-vertical" font-scale="1"></b-icon><span class="sr-only">Edit</span></span>
            </template>
             <b-dropdown-text class="mt-3"><b>{{ $t('admin.pharmacy.editTitle')}}</b></b-dropdown-text>
             <b-dropdown-form class="p-2">
                <b-form-input
                    class="form-input-style"
                    size="sm"
                    v-model="inputName"
                    :placeholder="translate('admin.pharmacy.name')"
                    :state="validationName"
                ></b-form-input>
                <b-form-input
                    class="form-input-style"
                    size="sm"
                    v-model="inputCode"
                    :placeholder="translate('admin.pharmacy.code')"
                    :state="validationCode"
                ></b-form-input>
                <b-form-input
                    class="form-input-style"
                    size="sm"
                    type="number"
                    v-model="inputLocalidad"
                    :placeholder="translate('admin.pharmacy.locality')"
                    :state="validationLocalidad"
                ></b-form-input>
                <b-form-input
                    class="form-input-style"
                    size="sm"
                    v-model="item.sede_origen"
                    disabled
                ></b-form-input>
                <b-row class="justify-content-end button-group">
                  <b-button
                    class="btn btn-blue-dark-outline ml-0"
                    @click="cancel(index)"
                  >
                      {{$t('admin.pharmacy.cancel')}}              
                  </b-button>
                  <b-button
                    class="btn btn-blue-dark ml-0"
                    @click="handleSavePharmacy(item._id)"
                    :disabled="!isValid"
                  >
                      {{$t('admin.pharmacy.save')}} <b-icon icon="arrow-right" class="icon" aria-hidden="true"></b-icon>
                  </b-button>
                </b-row>
             </b-dropdown-form>
            
          </b-dropdown>
           
        </div>
        
        </div>
        <div v-else>
         <b-alert show dismissible>
          <p>{{ $t("permissions.msgPermissionsPharmacy") }}</p>
        </b-alert>
      </div>
      </b-col>
    </b-row>
    <b-row>
      <b-button
        variant="outline-primary"
        class="button btn-blue-dark-outline ml-0"
        @click="$emit('handleBack')"
      >
          {{ $t('admin.pharmacy.Back')}}              
      </b-button>
    </b-row>
  </section>
</template>

<script>
import {API_HUBSPOT, API_ROOT} from '../../../Constants/Api';
import Swal from 'sweetalert2'
import { AlertForm, ICON } from '../../../helpers/Alert';
import { ApiRequest } from '../../../helpers/ApiRequest';

export default {
  name: 'FormPharmacy',
  components: {},
  props:['country', 'configRol'],
  data:() => ({
    sucursales: [],
    sucursalesFilter: [],
    sedeSelected: null,
    sedeInput: `--- Select an option ---`,
    filterSede: '',
    farmaciasList: [],
    inputName: '',
    inputCode: '',
    inputLocalidad: 0,
    inputSedeOrigen: null,
    messageError: '',
  }),
  

  async mounted() {
    try {
      let data = await ApiRequest.get(`${API_HUBSPOT}sucursales${this.country}`);
      let sucursales = data.sucursales;
      this.sucursales = sucursales.map((el) => ({ name: el, value: el}));
      this.sucursalesFilter = this.sucursales;
    } catch (err) {
      AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.pharmacy.errorBranch"), ICON.ERROR);
    }
  },
  watch: {
    filterSede() {
      this.sucursalesFilter = this.sucursales.filter(el => el.name.toLowerCase().includes(this.filterSede.toLowerCase()));
    }
  },
  computed: {
      validationName() {
        return this.validationInputName();
      },
      validationCode() {
        return this.validationInputCode();
      },
      validationLocalidad(){
        return this.validationInputLocalidad();
      },
      isValid(){
        return (this.validationInputName() && this.validationInputCode() && this.validationInputLocalidad())? true: false;
      },
    },
  
  methods: {
    validationInputName(){
      return this.inputName.length > 5;
    },
    validationInputCode(){
      return this.inputCode.length > 3;
    },
    validationInputLocalidad(){
      return this.inputLocalidad !== "" && this.inputLocalidad > 0;
    },
     async handleSede(sede){
      try {
        this.messageError= ''
        this.sedeInput = sede.name;
        const data = await ApiRequest.get(`${API_ROOT}farmacias/sede/${sede.name}`)
        this.farmaciasList = data.lista;
      } catch (err) {
        if(err.status === 401) {
          this.messageError = this.$i18n.t('admin.pharmacy.messageError');
          this.farmaciasList = '';
          return;
        }
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    async handleSavePharmacy(id){
      try {
        await ApiRequest.put(`${API_ROOT}farmacias`, {
          id,
          nombre: this.inputName,
          codigo: this.inputCode,
          localidad: this.inputLocalidad
          })
        AlertForm(this.$i18n.t('admin.pharmacy.update'), "", ICON.SUCCESS);
        const info = await ApiRequest.get(`${API_ROOT}farmacias/sede/${this.sedeInput}`);
        this.farmaciasList = info.lista;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.pharmacy.updateError'), ICON.ERROR);
      }   
    },
    cancel(index){
      this.$refs[`dropdownSede${index}`][0].hide(true)
    },
    handleDeletePharmacy(item){
      Swal.fire({
        title: `${this.$i18n.t('admin.pharmacy.Disassociate')}?`,
        html: `${this.$i18n.t('admin.pharmacy.message')} <b>${item.nombre}</b> ${this.$i18n.t('admin.pharmacy.message2')} <b>${this.sedeInput}</b>?`,
        padding: '3rem',
        showCancelButton: true,
        confirmButtonColor: '#325dab',
        cancelButtonColor: '#1e3153',
        confirmButtonText: this.$i18n.t('admin.pharmacy.save')
      }).then(async (result) => {
        try {
          if (result.isConfirmed) {
          await ApiRequest.delete(`${API_ROOT}farmacias`, {id: item._id});
          AlertForm( this.$i18n.t('admin.pharmacy.deleted'), "", ICON.SUCCESS );
          const info = await ApiRequest.get(`${API_ROOT}farmacias/sede/${this.sedeInput}`);
          this.farmaciasList = info.lista;
        }
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`,this.$i18n.t('admin.pharmacy.deletedError'), ICON.ERROR);
        }          
      })
    },
    editPharmacy(item){
      this.inputName= item.nombre;
      this.inputCode= item.codigo;
      this.inputLocalidad = item.localidad
    },
    translate(name) {
      return this.$i18n.t(name);
    }
  }
}    
</script>

<style scoped>
@import "../../../assets/css/global.css";


/**SELECT SEDE */
.select-sede {
  display: flex;
  align-items: center;
}

/**input search */
.groupSearch {
  display: grid;
  width: 300px;
  grid-template-columns: 0.3fr 0.7fr 6px;
}

.item__dropdown {
  margin: 0 1rem;
}

/**TABLE PHARMACY */
.tablePharmacy {
  display: grid;
  grid-template-columns: 1fr 170px 20px;
  padding: 0.6rem 1rem;
  border-bottom: 1px solid #a7a5a5;
  align-items: center;
}
.disassociatePharmacy{
  cursor:pointer;
  text-decoration: underline;
  color: #656565;
  font-size: 0.85rem;
}

.disassociatePharmacy:hover{
  opacity: 0.6;
}

/** BUTTON SWAL ALERT */
button.swal2-styled.swal2-cancel {
  color: #220404;
  border: 1px solid #220404;
}


#dropdown-sede /deep/ .form-sede  {
    width: 400px;
}

#dropdown-sede input.form-input-style {
    font-weight: bold;
    border: none;
    border-radius: 0%;
    border-bottom: 1px solid #7070708a;
    margin: 15px 0;
}
</style>>