<template>
<div class="container__pharmacy mt-4 ml-4">
  <section v-if="status === 'selectCountry'">
      <SelectCountry @next="handleNext" />
    </section>
    <section v-else-if="status === 'FormPharmacy'">
      <FormPharmacy @handleBack="status = 'selectCountry'" @handleCreate="handleCreate" :configRol="configRol"  :country="countrySelect"/>
    </section>
    <section v-else>
      <CreatePharmacy @handleCancel="cancel" :country="countrySelect"/>
    </section>
</div>
  
</template>
   
    
<script>
import SelectCountry from './PharmacyComponent/SelectCountry';
import FormPharmacy from './PharmacyComponent/FormPharmacy';
import CreatePharmacy from './PharmacyComponent/CreatePharmacy';
import { AlertForm, ICON } from '../../helpers/Alert';

export default {
  name: "Pharmacy",
  components: {SelectCountry, FormPharmacy, CreatePharmacy },
  data: () => ({
      status: 'selectCountry', //default selectCountry
      countrySelect: '',
      configRol: {}
  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if(this.configRol.administrador?.pestana.pdf.pharmacy === false) {
      this.$router.push({ name: "Welcome" });
      AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("admin.pharmacy.title")}` , this.$i18n.t("permissions.validateAdmin"), ICON.WARNING, "center", null);
    }
  },
  methods: {
    handleNext(e) {
      this.status = 'FormPharmacy'
      this.countrySelect = e;
    },
    handleCreate() {
      this.status = 'createPharmacy'
    },
    cancel(){
      this.status = 'FormPharmacy'
    }
  }
}
</script>

<style scoped>
</style>