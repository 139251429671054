<template>
  <div>
  <h4>{{ $t('admin.pharmacy.create')}}</h4>
  <b-form @submit="onSubmit"  class="mt-4">
    <b-row class="mb-5">
      <b-col class="inputGroup">
          <label for="input-1"><b>{{ $t('admin.pharmacy.name')}}</b></label>
          <b-form-input
            id="input-1"
            v-model="form.name"
            :placeholder="this.$i18n.t('admin.pharmacy.nameInput')"
            required
          ></b-form-input>
      </b-col>
      <b-col class="inputGroup">
        <label for="input-2"><b>{{ $t('admin.pharmacy.code')}}</b></label>
        <b-form-input
          id="input-2"
          v-model="form.imc"
          placeholder="IMC"
          required
        ></b-form-input>
      
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col class="inputGroup">
        <label for="input-3"><b>{{ $t('admin.pharmacy.locality')}}</b></label>
        <b-form-input
          id="input-3"
          v-model="form.localidad"
          :placeholder="this.$i18n.t('admin.pharmacy.locality')"
          required
          type="number"
        ></b-form-input>
      
      </b-col>
      <b-col class="inputGroup">
        <label for="input-4"><b>{{ $t('admin.pharmacy.branchInput')}}</b></label>
        <b-form-select
          id="input-4"
          v-model="form.sede"
          :options="sedeOrigen"
          required
        ></b-form-select>
      </b-col>
    </b-row> 
    <b-row class="justify-content-end">
      <b-button
          class="btn btn-blue-dark-outline ml-0"
          @click="$emit('handleCancel')"
      >
          {{ $t('admin.pharmacy.cancel')}}              
      </b-button>
      <b-button
        class="btn btn-blue-dark ml-0"
        type="submit"
        :disabled="isLoading"
      >
          {{ $t('admin.pharmacy.save')}}
      </b-button>
    </b-row>
    
    </b-form>
  </div>
</template>

<script>
import { API_HUBSPOT, API_ROOT } from '../../../Constants/Api';
import Swal from 'sweetalert2'
import { AlertForm, ICON } from '../../../helpers/Alert';
import { ApiRequest } from '../../../helpers/ApiRequest';

export default {
     name: "CreatePharmacy",
     props:['country'],
     data: () => ({
      form: {
          name: '',
          sede: null,
          imc: '',
          localidad:''
        },
      sedeOrigen: [],
      isLoading: false,
        
    }),
    async mounted() {
      try {
        let data = await ApiRequest.get(`${API_HUBSPOT}sucursales${this.country}`);
        let sucursales = data.sucursales;
        this.sedeOrigen = [{text: '---Select Branch---', value: null}, ...sucursales];
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`,  this.$i18n.t("admin.pharmacy.errorBranch"), ICON.ERROR);
      }
    },
    methods: {
      async onSubmit(event) {
        try {
          event.preventDefault()
          this.isLoading = true;
          const data = await ApiRequest.post(`${API_ROOT}farmacias`,{
            nombre: this.form.name,
            codigo: this.form.imc,
            sede_origen: this.form.sede,
            localidad: this.form.localidad
          });
           Swal.fire({
            icon: 'success',
            title: this.$i18n.t("admin.pharmacy.create"),
            html: `<b>${data.resultado.nombre}</b> ${this.$i18n.t("admin.pharmacy.savePharmacy")}`,
          })
          this.form= {
            name: '',
            sede: null,
            imc: '',
            localidad:''
          }
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.pharmacy.errorCreatePharmacy"), ICON.ERROR);
        } finally {
          this.isLoading = false;
        }
      },
    
  }
}
</script>

<style>

.inputGroup input.form-control,.inputGroup select.custom-select {
  
  background-color: #f5f5f5;
  border: 2px solid #e5e5e5;
  color: #686666;
  font-size: 14px;
}

</style>